/*add default styles  */
.mdx-editor-container ul {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  list-style-position: inside;
  margin-bottom: 1em;
}

.mdx-editor-container ol {
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  list-style-position: inside;
  margin-bottom: 1em;
}

.mdx-editor-container ul ul,
.mdx-editor-container ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 1em;
}

.mdx-editor-container ol ol,
.mdx-editor-container ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 1em;
}

.mdx-editor-container ul ul ul,
.mdx-editor-container ul ol ul,
.mdx-editor-container ol ul ul,
.mdx-editor-container ol ol ul {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1em;
}

.mdx-editor-container ul ul ul ul,
.mdx-editor-container ul ul ol ul,
.mdx-editor-container ul ol ul ul,
.mdx-editor-container ul ol ol ul,
.mdx-editor-container ol ul ul ul,
.mdx-editor-container ol ul ol ul,
.mdx-editor-container ol ol ul ul,
.mdx-editor-container ol ol ol ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1em;
}

.mdx-editor-container ul li {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container ol li {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}
/* if checkbox role then add padding */
.mdx-editor-container ul li[role="checkbox"],
.mdx-editor-container ol li[role="checkbox"] {
  padding-left: 1.5rem;
}

.mdx-editor-container ul li ul,
.mdx-editor-container ul li ol,
.mdx-editor-container ol li ul,
.mdx-editor-container ol li ol {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.mdx-editor-container li p {
  margin: 0;
  padding: 0;
}

.mdx-editor-container li li {
  margin-bottom: 0.5em;
}

.mdx-editor-container li p {
  margin-bottom: 0.5em;
}

.mdx-editor-container li ul {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.mdx-editor-container li ol {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.mdx-editor-container li ul li {
  margin-bottom: 0.5em;
}

.mdx-editor-container li ol li {
  margin-bottom:;
}

.mdx-editor-container h1 {
  font-size: 2em;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container h2 {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container h3 {
  font-size: 1.17em;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container h4 {
  font-size: 1em;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container h5 {
  font-size: 0.83em;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container h6 {
  font-size: 0.67em;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

.mdx-editor-container p {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

/* blackqupote */

/* show left line on quote  */
.mdx-editor-container blockquote::before {
  content: "";
  display: block;
  width: 0.25em;
  height: 100%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
}

.mdx-editor-container blockquote {
  position: relative;
  margin: 0;
  padding: 0;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  padding-left: 1em;
}

.mdx-editor-container blockquote p {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}

/* a tag default style */
.mdx-editor-container a {
  color: blue;
  text-decoration: unset;
}
